'use strict';

function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

$(document).on('submit', '.address-form-modal', function (e) {
    e.preventDefault();
    var url = $(this).attr('action');
    var form = $(this);
    var redirectURL = window.location.href;

    $(".input-content-cep button").addClass("is__loading");

    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: $(form).serialize(),

        success: function (data) {
            var cepProductId = $('#cepProductId').val();
            var element = $('button.add-to-cart-carousel[data-pid="' + cepProductId + '"]')[0];
            $('body').trigger('addToCart:event', element);

            //datalayer trigger add_cep
            $('body').trigger('dataLayerPush:event', data.add_cep);

            if (data && data.hasUnavailableProducts && data.unavailableProducts && data.unavailableProducts.length > 0) {
                showUnavailableProductsModal(data.unavailableProducts);
            } else {
                location.href = redirectURL;
            }
        },
        error: function (err) {
            $(".input-content-cep button").removeClass("is__loading");
            console.log(err);
        }
    });
});

$('#zipCodeModal').on('input', function() {
    let zipCode = $(this).val().replace(/\D/g, '');
    let url = $(this).data('url');

    if (zipCode.length > 5) {
        zipCode = zipCode.substring(0, 5) + '-' + zipCode.substring(5, 8);
    }

    $(this).val(zipCode);
    let postalCode = $(this).val();

    if ($(this).val().length === 9) {
        $(".btn-modal-cep").addClass("is__loading");
        $.ajax({
            url: appendToUrl(url, {postalCode}),
            type: 'GET',
            success: function (data) {
                $(".btn-modal-cep").removeClass("is__loading");

                if (data.success) {
                    var addressData = data.addressData
                    $('.modal-button.edit').removeClass('d-none');
                    $('#address1Modal').val(addressData.logradouro);
                    $('#address1Modal').prop('readonly', true);
                    $('#addressDistrictModal').val(addressData.bairro);
                    $('#addressDistrictModal').prop('readonly', true);
                    $('#inputStateModal').val(addressData.uf).change();
                    $('#inputStateModal').prop('readonly', true);
                    $('#inputCityModal').val(addressData.localidade);
                    $('#inputCityModal').prop('readonly', true);
                    $('.btn-modal-cep').removeAttr('disabled');
                } else {
                    $('#zipCodeModal').addClass('is-invalid').siblings('.invalid-feedback').html(data.error);
                    $('#address1Modal').prop('readonly', false);
                    $('#addressDistrictModal').prop('readonly', false);
                    $('#inputStateModal').prop('readonly', false);
                    $('#inputCityModal').prop('readonly', false);
                    $('.modal-button').filter(':visible').addClass('d-none');
                    $('.btn-modal-cep').attr('disabled', true);

                }
            },
            error: function (data) {
                $(".btn-modal-cep").removeClass("is__loading");
            }
        });
    } else {
        $('#address1Modal').val('');
        $('#addressDistrictModal').val('');
        $('#inputStateModal').val('');
        $('#inputCityModal').val('');
    }
});

function cep() {
    if (
        $('#inputStateModal').val() &&
        $('#address1Modal').val() &&
        $('#inputCityModal').val()
    ) {
        $.ajax({
            url: `https://viacep.com.br/ws/${$('#inputStateModal').val()}/${$('#inputCityModal').val()}/${$('#address1Modal').val()}/json/`,
            type: 'GET',
            success: function (data) {
                if (data.erro) {
                    $('.erroModal').addClass('is-invalid').siblings('.invalid-feedback').html('Dados inválidos');
                    $('.modal-button').filter(':visible').addClass('d-none');
                } else {
                    $('#zipCodeModal').val(data[0].cep);
                    $('form .form-group input').trigger('keypress');
                    $('.modal-button.edit.d-none').removeClass('d-none');
                }
            },
            error: function (data) { }
        });
        $('#address1Modal, #inputCityModal').on('input', function () {
            let address = $(this)
                .val()
                .replace(/[^a-zA-ZÀ-ÿçÇ ]/g, '');
            $(this).val(address);
        });

        $('.saveAddress-btn').on('click', function () {
            let fields = $('form .form-group input');

            fields.each(function () {
                let inputValue = $(this).val();
                if (inputValue.length < 1 && $(this).attr('required')) {
                    $(this)
                        .addClass('is-invalid')
                        .siblings('.invalid-feedback')
                        .html('Preencha esse campo');
                } else {
                    $(this)
                        .removeClass('is-invalid')
                        .siblings('.invalid-feedback')
                        .html('');
                }
            });
        });

        $('form .form-group input').on('keypress', function () {
            let inputValue = $(this).val();
            if (inputValue.length > 0) {
                $(this)
                    .removeClass('is-invalid')
                    .siblings('.invalid-feedback')
                    .html('');
            }
        });
    }
}

function validateUnavailableProducts(){
    var inputUnavailableProducts = $('input[name="unavailableProducts"]')
    var unavailableInformation = inputUnavailableProducts.val();
    var url = inputUnavailableProducts.data('action');
    if(unavailableInformation === 'true'){
        var redirectURL = window.location.href;
        $(".input-content-cep button").addClass("is__loading");
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
    
            success: function (data) {
                if (data.productsData.hasUnavailableProducts && data.productsData.unavailableProducts) {
                    showUnavailableProductsModal(data.productsData.unavailableProducts);
                    $('#cepModal').show()
                } else {
                    location.href = redirectURL;
                }
            },
            error: function (err) {
                $(".input-content-cep button").removeClass("is__loading");
                console.log(err);
            }
        });
    }
}

$('.button-edit-modalCep').on('click', function (event) {
    event.stopPropagation();
    $('.cardCep, .newCepFields, .btn-hiden ,.title-standard, .title-standard-edit').toggleClass('d-none');
});

$('#inputStateModal').on('input', function () {
    cep();
});
$('#address1Modal,#inputCityModal').on('blur', function () {
    cep();
});

$('body').on('click', '#saveButtonModal', function (e) {
    location.reload();
});

$('#localization').on('click', function () {
    if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
            function (position) {
                const coords = {
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                };
                const url = $('#localization').data('googleapi');
                $.ajax({
                    url: url.replace(
                        'usercoords',
                        `${coords.latitude},${coords.longitude}`
                    ),
                    method: 'get',
                    dataType: 'json',
                    success: function (data) {
                        $('#localization').data('googleapi');
                        $('#zipCodeModal').val(data.results[0].address_components[6].short_name);
                        $('#address1Modal').val(data.results[0].address_components[1].short_name);
                        $('#addressDistrictModal').val(data.results[0].address_components[2].short_name);
                        $('#inputCityModal').val(data.results[0].address_components[3].short_name);
                        $('#inputStateModal').val(data.results[0].address_components[4].short_name).change();
                        $('.modal-button.edit.d-none').removeClass('d-none');
                    },
                    error: function (err) { }
                });
            },
            function (error) {
                console.log(error);
            },
            { enableHighAccuracy: true }
        );
    } else {
        alert(
            'Lamento, mas os serviços de geolocalização não são suportados pelo seu browser.'
        );
    }
});

$('body').on('click', '.cardCep', function (e) {
    e.preventDefault();
    var url = $(this).data('action');
    var uuid = $(this).data('uuid');
    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: {
            uuid: uuid
        },
        success: function (data) {
            $('.locationIcon.click.desktop').html(data.shorTextLocation);
            $('#saveButtonModal').attr('data-address1', data.address.address1);
            $('#saveButtonModal').attr(
                'data-number',
                data.address.at_address_number
            );
            $('#saveButtonModal').attr('data-uuid', data.address.UUID);

            $('.cardCep').removeClass('selectedAddress-card');
            $(`.cardCep[data-uuid="${data.address.UUID}"]`).addClass(
                'selectedAddress-card'
            );

            location.reload();
        },
        error: function (err) {
        }
    });
});

$('body').on('click', '.redirect-editAddress', function (event) {
    var inputs = document.querySelectorAll('.address-form-modal input');

    var formNotEmpty = Array.from(inputs).every(function (input) {
        return input.value.trim() !== '';
    });

    if (formNotEmpty) {
        event.preventDefault();
        var linkUrl = $(this).data('href');
        window.open(linkUrl, '_blank');
    }
});

if ($('.cep-text').text().includes('Entrega em')) {
    $('.close-button').css('display', 'none');
    $('.modal.p-0.show').css('pointer-events', 'none');
}

$('.cep-text').click(function () {
    $('.close-button').css('display', 'block');
    $('.modal.p-0.show').css('pointer-events', 'auto');
});

/**
var timeoutId;
$(document).ready(function () {
    var popoverCEP = $('input[name="popover-cep-value"]').val();
    window.localStorage.setItem('popoverCEP', popoverCEP);
    if (popoverCEP !== 'true') {
        timeoutId = setTimeout(function() {
            $('.popover-cep').fadeIn(500);
        }, 7000);
    }
});

$('body').on('click', '.js-close-popover, .js-searchBar__input', function (e) {
    clearTimeout(timeoutId);
    $('.popover-cep').css('display', 'none');
});
 */
function calcHeightModalCep(){
    var modalCepHeight =$('.modalCepContent').height();
    var modalScroll = $( '.modal-list-scroll').first();
    var offset = modalScroll.offset();
    var heightList = modalCepHeight -  offset.top;
    return heightList;
}

$(document).on('shown.bs.modal', '#cepModal', function () {
    var viewSize = $(window).width();

    if (viewSize <= 430) {
        var heightList = calcHeightModalCep();
        $('.modal-list-scroll').css('height', heightList);
    }else{
        $('.modal-list-scroll').css('height', '188px');
    }

});

$( window ).on( "resize", function() {
    var modalCEP = ($("#cepModal").data('bs.modal') || {})._isShown ;

    if(modalCEP){
        var viewSize = $(window).width();

        if (viewSize <= 430) {
            var heightList = calcHeightModalCep();
            $('.modal-list-scroll').css('height', heightList);
        }else{
            $('.modal-list-scroll').css('height', '188px');
        }
    }
});

function modalObserver(){
    var modal = document.getElementById('cepModal');
    var observer = new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation) {
            if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                if (modal.classList.contains('show')) {
                    $('#cepProductId').val('');
                }
            }
        });
    });
    var config = { attributes: true };
    observer.observe(modal, config);
}

function checkLocationPermission() {
    var defaultCep = $('input[name="defaultCep"]').val();

    if( defaultCep !== 'false' && navigator.permissions){
        if (navigator.permissions) {
            navigator.permissions.query({ name: 'geolocation' }).then(function (permissionStatus) {
                if (permissionStatus.state === 'granted') {
                    return;
                } else {
                    requestLocationPermission()
                }
            });
        }
    }
}

function requestLocationPermission() {
    if (navigator.geolocation) {

        // reset button props
        $('.js-request-user-location').removeClass('disabled')
        $('.js-request-user-location').css('pointer-events','unset')

        navigator.geolocation.getCurrentPosition(
            function (position) {
                var latitude = position.coords.latitude;
                var longitude = position.coords.longitude;
                var currentLatlng = latitude+','+longitude

                // console.log("🚀 ~ navigator.geolocation latitude: ", latitude)
                // console.log("🚀 ~ navigator.geolocation longitude: ", longitude)

                var url = $('input[name="saveLocation"]').val();
                var latlng = $('input[name="latlng"]').val();
                var setByModal = $('input[name="setByModal"]').val();

                // if(latlng!==currentLatlng && setByModal!=='true') {
                    $.ajax({
                        url: url,
                        type: 'post',
                        dataType: 'json',
                        data: {
                            lat: latitude,
                            lng:longitude
                        },
                        success: function (data) {
                            //datalayer trigger add_cep
                            $('body').trigger('dataLayerPush:event', data.add_cep);
                            location.reload();
                        },
                        error: function () {
                            $('.js-request-user-location').removeClass('is__loading')
                        }
                    });
                // }

            },
            function (error) {
                console.error('Erro ao obter a localização: ', error);
                // disabled location button
                $('.js-request-user-location').removeClass('is__loading')
                $('.js-request-user-location').addClass('disabled')
                $('.js-request-user-location').css('pointer-events','none')
            }
        );
    } else {
        console.error('Geolocalização não é suportada por este navegador.');
        $('.js-request-user-location').removeClass('is__loading')
    }
}

function showUnavailableProductsModal(unavailableProducts) {
    var itemsHtml = '';
    var unavailableProductsIDList = [];
    var totalMessage = $('.msgWillRemoved').html();
    totalMessage = totalMessage.replace('totalProductsToBeRemoved', unavailableProducts.length);
    if (unavailableProducts.length == 1) {
        totalMessage = totalMessage.replace('produtos serão removidos', 'produto será removido');
    }
    $('.msgWillRemoved').html(totalMessage);

    for (var i = 0; i < unavailableProducts.length; i++) {
        var unavailableProduct = unavailableProducts[i];
        itemsHtml += 
            `<div class="productList-unavailable">
                <div class="productDetail">
                    <img class="img-fluid" src="${unavailableProduct.images.small.url}" alt="${unavailableProduct.images.small.alt}" />
                    <p class="productName m-0">${unavailableProduct.product.name}</p>
                </div>`;
        
        if (unavailableProduct.allItemsUnavailable) {
            itemsHtml += '<p class="text-center unavailableMsg"><b>Indisponível</b></p>'
        } else {
            var quantityAddedToBasket = unavailableProduct.quantityAddedToBasket.toString();
            var quantityAvailable = unavailableProduct.ats.toString();
            itemsHtml += `<p class="text-center changeUnit m-0"><b>${quantityAddedToBasket}un</b> → ${quantityAvailable}un</p>`;
        }

        itemsHtml += '</div>';
        unavailableProductsIDList.push(unavailableProduct.product.id);
    }

    $('.containerProduct-unavailable').html(itemsHtml);
    $('.modalCepContent').addClass('d-none');
    $('.modal-unavailableProduts').removeClass('d-none');
    $('.unavailable-modal-remove').data('productList', { unavailableProductsIDList: unavailableProductsIDList })
    
    $(document).click(function(event) {
        if ($('.modal-unavailableProduts').length && !$('.modal-unavailableProduts').hasClass('d-none')) {
            if($(event.target).closest('.closeUnavailable-modal').length){
                $('.unavailable-modal-cancel').click();
            }else if(!$(event.target).closest('.modal-unavailableProduts').length && !$(event.target).is('.modal-unavailableProduts')){
                $('body').spinner().start();
                $('.unavailable-modal-cancel').click();
            }
        }
    });
}



function loadUnavailableProductsModalEvents(){
    $('.unavailable-modal-cancel, .unavailable-modal-remove').on('click', function (e) {
        $(this).addClass("is__loading");
        var totalProducts = $('.containerProduct-unavailable').length;
        var totalMessage = totalProducts == 1  ? 'Os produtos foram removidos do carrinho.':'O produto foi removido do carrinho.'
        
        e.preventDefault();
        var url = $(this).data('url');
        var input = $(this).data('productList') ? $(this).data('productList') : {};
        input = JSON.stringify(input);

        $.ajax({
            url: url,
            data: input,
            type: 'POST',
            dataType: 'json',
            contentType: "application/json",
            success: function (data) {
                if(data.removeProduct){
                    $('.modal-unavailableProduts').empty().append('<div class="alert alert-light alert-dismissible'  +
                    'fade show text-center m-0" role="alert"><b style="color:#002B70">'+ totalMessage +'</b></div><div class="is__loading"></div>')
                    setTimeout(() => {
                        location.href = window.location.href;
                    }, 200);

                } else{
                    location.href = window.location.href;
                }
            },
            error: function (err) {
                $(this).removeClass("is__loading");
                console.log('Erro ao executar ações do modal de produtos indísponíves.');
            }
        });

        return false;
    });
}

$('body').on('click', '.js-request-user-location', function (e) {
    $('.js-request-user-location').addClass('is__loading')
    requestLocationPermission()
});

//document ready
$(document).ready(function () {
    var modalId = localStorage.getItem('openCEPModal');
    if(modalId != null){
        $(modalId).modal("show"); //use modal with id addEventModal
        localStorage.removeItem('openCEPModal');
    }

    $('#address1Modal, #inputCityModal').on('input', function () {
        let address = $(this)
            .val()
            .replace(/[^a-zA-ZÀ-ÿçÇ ]/g, '');
        $(this).val(address);
    });

    $('.saveAddress-btn').on('click', function () {
        let fields = $('form .form-group input');

        fields.each(function () {
            let inputValue = $(this).val();
            if (inputValue.length < 1 && $(this).attr('required')) {
                $(this)
                    .addClass('is-invalid')
                    .siblings('.invalid-feedback')
                    .html('Preencha esse campo');
            } else {
                $(this)
                    .removeClass('is-invalid')
                    .siblings('.invalid-feedback')
                    .html('');
            }
        });
    });

    $('form .form-group input').on('keypress', function () {
        let inputValue = $(this).val();
        if (inputValue.length > 0) {
            $(this)
                .removeClass('is-invalid')
                .siblings('.invalid-feedback')
                .html('');
        }
    });

    // exibe modal de cep ao carregar a pagina
    if ($('#cepLinkText').text().includes('Entrega em')) {
        $('.close-button').css('display', 'none');
        $('.modal.p-0.show').css('pointer-events', 'none');
    }

    // permissão de localização ao carregar a pagina
    checkLocationPermission();

    // observer cep modal
    modalObserver();

    // verifica produtos indisponíveis ao trocar de CEP ao logar
    validateUnavailableProducts();

    loadUnavailableProductsModalEvents();
});